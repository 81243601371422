(function ($) {

    let body = $('body');

    //question
    $(document).on('click', '.home-question-list', function (e) {
        $('.home-question-list').addClass('dsp-none')
        $('.question-block').removeClass('dsp-none')

        if (e.target.parentElement.getAttribute('data-status') === 'false') {
            $('.question-image-false').addClass('question-silhouette silhouette-false')
        } else {
            $('.question-image-true').addClass('question-silhouette silhouette-true')
        }
        e.preventDefault()
    })

    $('.height-style').css({'min-height': `${$('.home-block-question-style').height() - 123}px`});

    //scroll fixed header
    $(document).scroll(function () {
        if ($(window).width() < 769) {
            if ($(document).scrollTop() > 68) {
                $('.header').addClass('fixed')
            } else {
                $('.header').removeClass('fixed')
            }
        }
    })

    //nav-menu
    $('.drop-down>a').click(function (e) {
        e.preventDefault()
    })

    $('.drop-down').click(function (e) {
        if ($(window).width() <= 769) {
            if (!e.target.className.includes('active')) {
                $('.drop-down').removeClass('active')
                $(e.target).toggleClass('active')
            } else {
                $('.drop-down').removeClass('active')
            }
        }
    }).mouseenter(function (e) {
        e.preventDefault()
        if ($(window).width() >= 768) {
            $(this).addClass('active')
        }
    }).mouseleave(function (e) {
        e.preventDefault()
        if ($(window).width() >= 768) {
            $(this).removeClass('active')
        }
    }).keydown(function (e) {
        if (e.keyCode == 13) {
            $(this).addClass('active')
        }
    });

    if($(body).hasClass('cat-3569-id')) {
        $('#nav-menu > .subject-page').toggleClass('current-menu-item')
    }
    if($(body).hasClass('page-template-page-protocols-group') || $(body).hasClass('cat-3559-id')){
        $('#nav-menu > .protocol-page').toggleClass('current-menu-item')
    }
    if($(body).hasClass('page-template-page-envelopes-group') || $(body).hasClass('cat-3570-id')){
        $('#nav-menu > .envelop-page').toggleClass('current-menu-item')
    }

    //protocol drop-down
    $('.sub-prot-enve-content > ul > li > .arrow-element').click(function (e) {
        $(e.target.nextElementSibling).toggleClass('active-drop-down')
        $(e.target).toggleClass('active-arrow')
        e.preventDefault()
    })

    //burger
    $('#navigationToggle').click(function (e) {
        if ($(window).width() <= 769) {
            $('#navigation > .nav-list').toggleClass('nav-active')
            $('#navigation > .nav-active').append($('.header-links').toggleClass('active-header-links'))
            $('#navigationToggle').toggleClass('burger-active')
            $('body').toggleClass('overflow-body')
            e.preventDefault();
        }
    })

    //contact-us popup
    document.addEventListener('wpcf7submit', function (event) {

        setTimeout(function () {
            if ($('.input').hasClass('wpcf7-not-valid') === false) {
                $('.popup').addClass('active')
                $('.wrapper').addClass('no-click-wrapper');
                $('.close-popup-btn, .no-click-wrapper').click(function (e) {
                    $('.popup').remove()
                    $('.wrapper').removeClass('no-click-wrapper')
                    e.preventDefault()
                })
            }
        }, 0);
    }, false);

    $('.section-protocol').append('<div class="box"> <div class="container-box"><span class="circle"></span><span class="circle"></span><span class="circle"></span><span class="circle"></span></div></div>')

    //form input-search
    $('.input-search').bind('change', function (e) {
        $(e.currentTarget).val($(e.currentTarget).val().trim())
    });

    $(window).resize(function () {
        //nav-menu burger-menu
        if ($(window).width() >= 768) {
            $('.header-links').removeClass('active-header-links')
            $('.header-links-block').append($('.header-links'))
        }
        if ($(window).width() >= 700) {
            $('#navigation > .nav-list').removeClass('nav-active')
            $('#navigationToggle').removeClass('burger-active')
        }
    });

    // map hover
    $('#image-map-block area').mouseenter(function (event) {
        $('#' + $(this).attr('data-area')).addClass('active-area');
    });

    $('#image-map-block area').mouseleave(function (event) {
        $('#' + $(this).attr('data-area')).removeClass('active-area');
    });




})(jQuery)
